<template>
  <!-- 在线用户 -->
  <div class="online-user" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 搜索区域 -->
          <seniorSearch v-model="queryParams" :getList="getList" :isSearchInput="true" :isBillDate="false"
            :dropDownList="dropDownList" @isShowHighCom="getIsShowHigh" />
          <div v-show="showHigh" class="marT10 x-w">
            <div class="x-f">
              <span class="fS14MR10">登录端口</span>
              <el-select size="mini" class="inputWidth marR15" v-model="queryParams.loginFrom" placeholder="全部"
                filterable clearable>
                <el-option v-for="dict in dict.type.login_from" :key="dict.value" :label="dict.label"
                  :value="parseInt(dict.value)">
                </el-option>
              </el-select>
            </div>
            <div class="x-f">
              <span class="fS14MR10">token</span>
              <el-input size="mini" class="inputWidth" v-model="queryParams.token" placeholder="全部"
                clearable></el-input>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="用户列表">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 按钮区域 -->
          <operatingButton :multiple="multiple" :getList="getList" :isEnableBtn="true" :isAddBtn="false"
            :isDeleteBtn="false" @handleEnable="handleEnable">
            <template slot="specialDiyBtn">
              <el-button type="primary" size="mini" @click="downline">下线
              </el-button>
            </template>
          </operatingButton>
          <!-- 表格区域 -->
          <div class="table">
            <!-- 表格 -->
            <el-table :data="tableData" :height="tableHeight" :max-height="tableHeight" border ref="multipleTable"
              style="width: 100%" @row-click="handleRowClick" v-loading="loadingTable"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50" align="center"></el-table-column>
              <el-table-column type="index" label="序号" width="50" align="center">
              </el-table-column>
              <el-table-column show-overflow-tooltip :prop="item.prop" :label="item.title" :width="item.width"
                v-for="item in tableFields" :key="item.title" align="center">
              </el-table-column>
            </el-table>
            <TablePagination :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :total="total"
              @pagination="getList"></TablePagination>
            <!-- 
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="ids"
              :totalCom="total"
              @selectAllCom="selectAll"
            /> -->
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import OperatingButton from '@/views/components/operatingButton/index.vue' //按钮区域组件
import TablePagination from '@/components/tablePage/tablePagination' //底部分页
import { list, forceLogoutMultiple, isStopApi } from '@/api/monitor/online' //在线用户
// import bottomPage from '@/views/components/bottomPage' //底部分页
export default {
  name: 'OnlineUser',
  dicts: ['login_from'],
  components: {
    cardTitleCom,
    seniorSearch,
    OperatingButton,
    TablePagination,
    // bottomPage
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      tableData: [],
      ids: [], // 选中数组
      total: 0, // 总条数
      //汇总表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'userName', title: '用户名称' },
        { prop: 'tenantNo', title: '商户号' },
        { prop: 'tenantName', title: '商户名称' },
        { prop: 'shopNo', title: '登录门店编号' },
        { prop: 'shopName', title: '登录门店名称' },
        { prop: 'telephone', title: '手机号' },
        { prop: 'token', title: 'token' },
        { prop: 'loginFromName', title: '登录端口' },
        { prop: 'lastLoginTime', title: '最后使用时间' },
        { prop: 'onLineTime', title: '在线时长' }
      ],
      loadingTable: false, //表格
      //重置查询参数
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'userNames', name: '用户名称' },
        { parameter: 'tenantNos', name: '商户号' },
        { parameter: 'tenantNames', name: '商户名称' },
        { parameter: 'shopNos', name: '登录门店编号' },
        { parameter: 'shopNames', name: '登录门店名称' },
        { parameter: 'telephones', name: '手机号' }
      ],
      showHigh: false,
      multiple: true, //禁用
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70 // 分页组件高度
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 120
    }
  },

  async mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
    this.queryParams.tenantId = this.$route.query.tenantId
    await this.getList()
    console.log('1111', this.$route.query)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    async getList() {
      this.loadingTable = true
      const res = await list(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await list({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    handleSelectionChange(selection) {
      this.ids = selection
        .map(item => item.token)
        .filter(ite => typeof ite !== 'undefined') //用户id
      this.multiple = !selection.length
    },
    //点击行选中
    handleRowClick(row) {
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //启用禁用
    async handleEnable() {
      await isStopApi(this.ids)
    },
    //下线
    downline() {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$confirm('您确认要下线选中的用户吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await forceLogoutMultiple(this.ids)
            await this.getList()
            this.$message({
              type: 'success',
              message: '下线成功!'
            })
          })
          .catch(() => { })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.online-user {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;

      .el-input,
      .el-select {
        width: 170px;
      }
    }
  }

  .tableContent {
    padding: 10px;
  }
}
.startUsing {
  margin-left: 10px;
  // margin-right: 10px;

  ::v-deep.el-button-group > .el-button {
    background-color: #ffba00 !important;
    color: #fff;
  }

  ::v-deep.el-button-group > .el-button:hover {
    background-color: #f9a13a !important;
  }
}
</style>
