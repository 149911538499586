<template>
  <div class="editUserDialog">
    <el-dialog
      title="编辑用户"
      :visible.sync="openDialog"
      v-dialogDrag="true"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content">
        <el-form label-width="75px">
            <el-form-item label="用户名称">
            <el-input v-model="form.vipNo" placeholder="请输入用户名称" />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.vipNo" placeholder="请输入手机号" />
            </el-form-item>
        </el-form>
        <div class="btn">
            <el-button  @click="handleClose">取 消</el-button>
            <el-button type="primary">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name:'EditUserDialog',
    props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {},
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:openDialog', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.editUserDialog{
    ::v-deep .el-dialog{
        max-width: 400px !important;
        min-width: 400px !important;
    }
    ::v-deep .el-dialog__body{
        padding: 0 20px !important;
    }
  .el-input,.el-select{
    width: 100%;
  }
    .btn{
        text-align: right;
        padding: 10px 0;
    }
}
</style>