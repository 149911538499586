<template>
  <!-- 交易统计 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      <template slot="product"></template>
    </TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { merchantList } from '@/api/tenant/saleData'
import TablePage from '@/components/tablePage/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import { listShopInfo } from '@/api/shop/base/shopInfo'
export default {
  name: 'RetailAbort',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        getListApi: merchantList,
        title: '商户数据异常查询',
        rowKey: 'billId',
        type: 'billId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          {
            type: 'local',
            label: '商户',
            model: '',
            filter: 'tenantIds',
            seniorSearch: true,
            option: {
              change: v => {
                this.options.search[4].option.remoteBody = { tenantIds: v }
                this.options.search[4].option.disabled = Boolean(!v?.length)
              },
              multiple: true,
              remote: AllTenantAPI,
              downLoad: 10,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '门店',
            model: '', //
            filter: 'shopIds',
            seniorSearch: true,
            option: {
              disabled: true,
              multiple: true,
              remote: listShopInfo,
              downLoad: 10,
              label: 'shopName',
              value: 'shopNo',
              filterable: true,
              labels: [
                { title: '门店编号', label: 'shopNo' },
                { title: '门店名称', label: 'shopName' }
              ]
            }
          },
          {
            type: 'local',
            label: '状态',
            model: '120103', //
            filter: 'stau',
            seniorSearch: true,
            option: {
              data: [
                { label: '显示全部', value: '120102' },
                { label: '显示异常', value: '120103' },
                { label: '显示正常', value: '120105' }
              ],
              label: 'label',
              value: 'value',
              filterable: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '160' },
          { prop: 'tenantName', label: '商户名称', minWidth: '160' },
          { prop: 'shopName', label: '门店编号', minWidth: '160' },
          { prop: 'shopName', label: '门店名称', minWidth: '160' },
          { prop: 'shopName', label: '日期', minWidth: '160' },
          ...[
            { label: '预订', prop: 'payTypse' },
            { label: '销售', prop: 'payTypse1' },
            { label: '充值', prop: 'payTypse2' }
          ].map(x => ({
            ...x,
            align: 'center',
            children: [
              { prop: 'aaa', label: '后台笔数', minWidth: '160' },
              { prop: 'bbb', label: '门店笔数', minWidth: '160' },
              { prop: 'ccc', label: '后台金额', minWidth: '160' },
              { prop: 'ccc', label: '门店金额', minWidth: '160' },
              { prop: 'ccc', label: '比较结果', minWidth: '160' }
            ]
          }))
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {}
  }
}
</script>
