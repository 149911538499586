var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "wrap x-f",
      },
      [
        _c(
          "cardTitleCom",
          { staticClass: "district", attrs: { cardTitle: "行政地区" } },
          [
            _c(
              "template",
              { slot: "cardContent" },
              [
                _c(
                  "el-scrollbar",
                  { staticStyle: { height: "calc(100vh - 150px)" } },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        "node-key": "id",
                        "highlight-current": "",
                        data: _vm.data,
                        props: _vm.defaultProps,
                        "default-expanded-keys": _vm.expandedKeys,
                        "current-node-key": _vm.defaultSelectedNodeId,
                      },
                      on: { "node-click": _vm.handleNodeClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "cardTitleCom",
          { staticClass: "right", attrs: { cardTitle: "地区详情" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "right", class: { fixedForm: _vm.isFixed } },
                [
                  _c(
                    "div",
                    { staticClass: "marT10 marB10 marL10" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "mb8", attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-plus",
                                    size: "mini",
                                  },
                                  on: { click: _vm.addRegion },
                                },
                                [_vm._v("新增下级")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    size: "mini",
                                    disabled: _vm.multiple,
                                  },
                                  on: { click: _vm.delRegion },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.refresh($event)
                                    },
                                  },
                                },
                                [_vm._v("刷新")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.form,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-scrollbar",
                        { staticStyle: { height: "calc(100vh - 150px)" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级编号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请选择上级编号",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.parentRegionNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentRegionNo", $$v)
                                  },
                                  expression: "form.parentRegionNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级名称" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请选择上级名称",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.parentRegionName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentRegionName", $$v)
                                  },
                                  expression: "form.parentRegionName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "区划编号", prop: "regionNo" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请输入区划编号" },
                                  model: {
                                    value: _vm.form.regionNoSuffix,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "regionNoSuffix", $$v)
                                    },
                                    expression: "form.regionNoSuffix",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v(_vm._s(_vm.form.regionNoPrefix)),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "区划名称", prop: "regionName" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入区划名称" },
                                model: {
                                  value: _vm.form.regionName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "regionName", $$v)
                                  },
                                  expression: "form.regionName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "区划排序", prop: "sortNo" } },
                            [
                              _c("el-input-number", {
                                attrs: { "controls-position": "right", min: 1 },
                                model: {
                                  value: _vm.form.sortNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sortNo", $$v)
                                  },
                                  expression: "form.sortNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "区划等级", prop: "regionType" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.regionType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "regionType", $$v)
                                    },
                                    expression: "form.regionType",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.region_type,
                                  function (dict) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: dict.value,
                                        attrs: { label: parseInt(dict.value) },
                                      },
                                      [_vm._v(_vm._s(dict.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "区划备注" } },
                            [
                              _c("el-input", {
                                staticClass: "desc",
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入区划备注",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-check",
                                  },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v("提交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { icon: "el-icon-delete" },
                                  on: { click: _vm.reset },
                                },
                                [_vm._v("清空")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }