var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handleOssDialog" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: _vm.title,
            visible: _vm.openDialog,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "90px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.form.isStop,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isStop", $$v)
                          },
                          expression: "form.isStop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "oss类型", prop: "ossId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择oss类型",
                            clearable: "",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.$refs.form.validateField("ossId")
                            },
                          },
                          model: {
                            value: _vm.form.ossId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ossId", $$v)
                            },
                            expression: "form.ossId",
                          },
                        },
                        _vm._l(_vm.dict.type.oss_config_id, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "资源编号", prop: "ossNo" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入资源编号" },
                        model: {
                          value: _vm.form.ossNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ossNo", $$v)
                          },
                          expression: "form.ossNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "资源地址", prop: "ossAddress" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入资源地址" },
                        model: {
                          value: _vm.form.ossAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ossAddress", $$v)
                          },
                          expression: "form.ossAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "空间名", prop: "ossSpaceName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入空间名" },
                        model: {
                          value: _vm.form.ossSpaceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ossSpaceName", $$v)
                          },
                          expression: "form.ossSpaceName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "accessKey", prop: "accessKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入accessKey" },
                        model: {
                          value: _vm.form.accessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accessKey", $$v)
                          },
                          expression: "form.accessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "secretKey", prop: "secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入secretKey" },
                        model: {
                          value: _vm.form.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "secretKey", $$v)
                          },
                          expression: "form.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }