<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <!-- 删除的对话框 -->
    <c-dialog title="删除日志" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="100px">
          <el-form-item label="删除范围" prop="datetime">
            <el-date-picker unlink-panels size="mini" v-model="form.datetime" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:300px" :picker-options="pickerOptions"
              ref="datePicker" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleDelete">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { getDicts } from '@/api/system/dict/data'
import { cashierOrderLog, deleteCashierOrderLog } from '@/api/tenant/saleData'
import TablePage from '@/components/tablePage' //表格页面组件
export default {
  name: 'retailAbortLog',
  components: { TablePage, CDialog },
  data() {
    return {
      title: "新增品牌", //控制新增修改弹框标题
      dialogLoading: false, //弹框loading
      dialogFormVisible: false,  //弹框
      form: {
      },
      rules: {
        datetime: [
          {
            required: true,
            message: "删除范围不能为空",
            trigger: ["blur", "change"],
          },
        ]
      },
      options: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '收银端异常单',
        getListApi: cashierOrderLog,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'id',
        //搜索
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: 'filters',
            tip: '单据编号',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户编号' },
              { filter: 'tenantNames', label: '商户名称' },
              { filter: 'billNos', label: '单据编号' },
            ]
          },
          {
            seniorSearch: true,
            type: 'local',
            label: '订单类型',
            model: '',
            filter: 'orderType',
            option: {
              clearable: true,
              filterable: true,
              remote: getDicts,
              remoteBody: 'bill_order_type',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          {
            seniorSearch: true,
            type: 'local',
            label: '异常订单',
            model: '', //
            filter: 'isError',
            option: {
              clearable: true,
              data: [
                { label: '是', value: true },
                { label: '否', value: false },
              ],
              label: 'label',
              value: 'value',

            }
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //按钮
        buttons: [
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            noRule: true
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'tenantNo',
            label: '商户号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'tenantName',
            label: '商户名称',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'orderTypeName',
            label: '订单类型',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billNo',
            label: '单据编号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'isError',
            label: '是否异常',
            minWidth: 80,
            align: 'center',
            formatter: (propValue) => {
              return propValue ? "是" : "否";
            },
          },
          {
            prop: 'message',
            label: '返回信息',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'formName',
            label: '日志来源',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'methodName',
            label: '调用方法',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'requestParam',
            label: '接口请求入参',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'requestUrl',
            label: '请求url',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'responseValue',
            label: '接口调用返回值',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  computed: {
    pickerOptions() {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      return {
        disabledDate(time) {
          // 禁止选择今天之后的日期和一个月之前的日期之前的日期
          return time.getTime() > Date.now()
        }
      };
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'del':
          this.dialogFormVisible = true;
          break
        default:
          break
      }
    },
    formatDate(date) {
      // 格式化日期为 'yyyy-MM-dd'
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    //取消
    cancel() {
      this.reset();
      this.dialogFormVisible = false;
    },
    // 表单重置
    reset() {
      this.form = {
      };
      this.resetForm("ruleForm");
    },
    // 删除按钮
    async handleDelete() {
      await this.$refs["ruleForm"].validate();
      let endBillDateString = this.form.datetime[1]
      // 将字符串转换为Date对象
      let endBillDate = new Date(endBillDateString);
      // 获取当前日期
      let today = new Date();
      // 计算两个日期之间的毫秒差
      let diffInMilliseconds = Math.abs(endBillDate - today);
      // 将毫秒差转换为天数
      let diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
      // 判断天数差是否大于30
      if (diffInDays < 30) return this.$message.error('只能删除30天之前的日志！');
      this.$confirm(`确定删除所选日期范围的日志吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      }).then(() => {
        return deleteCashierOrderLog({ beginBillDate: this.form.datetime[0], endBillDate: this.form.datetime[1] }); //删除请求
      })
        .then(() => {
          this.cancel()
          this.$refs.tablePage.getList(); //渲染列表
          this.$modal.msgSuccess("删除成功！");
        })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
