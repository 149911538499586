<template>
  <!-- 用户列表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <!-- 密码重置 -->
    <resetPWDialog :openDialog.sync="openResetPWDialog" :form="form" @handleResetPW="handleResetPW" />

    <!-- 编辑用户 -->
    <EditUserDialog :openDialog.sync="openEditUserDialog" />
  </div>
</template>

<script>
import { getDicts } from '@/api/system/dict/data'
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { tenantUserList, restoreUser, updateUserStatus, removeUser } from '@/api/tenant/user/list'
import { uinitPasswordAPI } from '@/api/system/employee'
import TablePage from '@/components/tablePage/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import resetPWDialog from '@/views/tenant/components/resetPWDialog' //重置密码弹窗
import EditUserDialog from './components/editUserDialog.vue' //编辑用户弹窗

export default {
  name: 'RetailTotal',
  dicts: ['common_status'],
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    resetPWDialog,
    EditUserDialog
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          userName: undefined, //用户名称
          telephone: undefined, //手机号码
          status: undefined //状态
        },
        getListApi: tenantUserList,
        title: '用户列表',
        rowKey: 'userId',
        // type: 'userId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: '用户名称',
            type: 'input',
            tip: '请输入用户名称',
            model: '',
            filter: 'nickName',
            seniorSearch: true
          },
          {
            type: 'local',
            label: '用户状态',
            model: '',
            filter: 'status',
            seniorSearch: true,
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'tenant_service_state',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'start',
            label: '启用',
            alertText: '确认要启用选中方案吗？',
            btnType: 'dropdown',
            other: [{ click: 'close', label: '禁用' }],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'password',
            label: '密码重置',
            type: 'primary',
            disabled: () => this.options?.check?.length !== 1
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            // alertText: '确认要删除选中的通知公告吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: restoreUser,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          { prop: 'userName', label: '用户账号' },
          { prop: 'nickName', label: '用户名称' },
          {
            prop: 'status',
            type: 'dicts',
            name: 'common_status',
            label: '状态'
          },
          { prop: 'createTime', label: '创建时间' },
          { prop: 'loginDate', label: '最后一次登录时间' }
        ],
        list: []
      },

      openResetPWDialog: false,
      openEditUserDialog: false,
      form: {},

      refs: null
    }
  },
  mounted() {
    this.refs = this.$refs.tablePage
  },
  methods: {
    // 提交重置密码
    handleResetPW(pw) {
      console.log('pw = ', pw)
      uinitPasswordAPI({
        password: pw,
        userId: this.options.check[0].userId
      }).then(res => {
        this.openResetPWDialog = false
        this.$message.success('密码修改成功')
      })
    },

    handleEvent(type, row) {
      const iDs = this.options.check.map(x => x.userId)
      const that = this
      switch (type) {
        case 'link':
          this.openEditUserDialog = true
          break
        case 'start':
          const filter = that.options.check.filter(item => item.status === '1')

          if (filter.length > 0) {
            const name = filter.map(item => item.nickName)
            return this.$message.error(`当前已有用户${name}被禁用`)
          }

          this.$modal.confirm('是否确认启用选中用户?').then(function () {
            updateUserStatus({
              status: 0,
              userIds: iDs
            }).then(res => {
              that.$message.success('启用成功')
              that.refs.getList()
            })
          })
          break
        case 'close':
          this.$modal.confirm('是否确认禁用选中用户?').then(function () {
            updateUserStatus({
              status: 1,
              userIds: iDs
            }).then(res => {
              that.$message.success('禁用成功')
              that.refs.getList()
            })
          })
          break
        case 'del':
          this.$modal.confirm('是否确认删除选中用户?').then(function () {
            removeUser(iDs).then(res => {
              that.$message.success('删除成功')
              that.refs.getList()
            })
          })
          break
        case 'password':
          this.openResetPWDialog = true
          break
      }
    }
  }
}
</script>
