<template>
  <div>
    <div class="wrap x-f" v-loading="loading">
      <cardTitleCom cardTitle="行政地区" class="district">
        <template slot="cardContent">
          <el-scrollbar style="height: calc(100vh - 150px)">
            <el-tree
              ref="tree"
              node-key="id"
              highlight-current
              :data="data"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :default-expanded-keys="expandedKeys"
              :current-node-key="defaultSelectedNodeId"
            ></el-tree>
          </el-scrollbar>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="地区详情" class="right">
        <template slot="cardContent">
          <div class="right" :class="{ fixedForm: isFixed }">
            <!-- 按钮区域 -->
            <div class="marT10 marB10 marL10">
              <el-row :gutter="10" class="mb8">
                <el-col :span="1.5">
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="addRegion"
                    >新增下级</el-button
                  >
                </el-col>
                <el-col :span="1.5">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="delRegion"
                    >删除</el-button
                  >
                </el-col>

                <!-- <el-col :span="1.5">
              <el-button
                type="info"
                plain
                icon="el-icon-upload2"
                size="mini"
                @click="handleImport"
                disabled
                >导入</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="warning"
                plain
                icon="el-icon-download"
                size="mini"
                @click="handleExport"
                disabled
                >导出</el-button
              >
            </el-col> -->
                <el-col :span="1.5">
                  <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="refresh($event)"
                    >刷新</el-button
                  >
                </el-col>
              </el-row>
            </div>
            <!-- 表单区域 -->
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
              <el-scrollbar style="height: calc(100vh - 150px)">
                <el-form-item label="上级编号">
                  <el-input
                    v-model="form.parentRegionNo"
                    placeholder="请选择上级编号"
                    :disabled="true"
                  ></el-input>
                </el-form-item>

                <el-form-item label="上级名称">
                  <el-input
                    v-model="form.parentRegionName"
                    placeholder="请选择上级名称"
                    :disabled="true"
                  ></el-input>
                </el-form-item>

                <el-form-item label="区划编号" prop="regionNo">
                  <el-input
                    placeholder="请输入区划编号"
                    v-model="form.regionNoSuffix"
                  >
                    <template slot="prepend">{{
                      form.regionNoPrefix
                    }}</template>
                  </el-input>
                </el-form-item>

                <el-form-item label="区划名称" prop="regionName">
                  <el-input
                    v-model="form.regionName"
                    placeholder="请输入区划名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="区划排序" prop="sortNo">
                  <el-input-number
                    v-model="form.sortNo"
                    controls-position="right"
                    :min="1"
                  ></el-input-number>
                </el-form-item>

                <el-form-item label="区划等级" prop="regionType">
                  <el-radio-group v-model="form.regionType">
                    <el-radio
                      v-for="dict in dict.type.region_type"
                      :key="dict.value"
                      :label="parseInt(dict.value)"
                      >{{ dict.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="区划备注">
                  <el-input
                    class="desc"
                    type="textarea"
                    v-model="form.remark"
                    placeholder="请输入区划备注"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    icon="el-icon-check"
                    >提交</el-button
                  >
                  <el-button @click="reset" icon="el-icon-delete"
                    >清空</el-button
                  >
                </el-form-item>
              </el-scrollbar>
            </el-form>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </div>
</template>

<script>
import {
  getRegionTree,
  getRegionDetail,
  delRegion,
  updateRegion,
  addRegion
} from '@/api/goods/region'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
export default {
  name: 'administrative',
  dicts: ['region_type'],
  components: {
    cardTitleCom
  },
  data () {
    return {
      defaultSelectedNodeId: 5, // 默认点击的节点id
      expandedKeys: [], // 用于存储展开节点的key的数组
      isFixed: false, //控制表单是否固定
      num: null, //区划编号左边
      type: null, //区分新增或者编辑的字段
      loading: false, //加载
      data: [], //树形数据
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      multiple: true, // 非多个禁用
      showRegion: true, //选择1级行政地区才能添加子级
      clickNode: {
        id: undefined
      }, //点击的节点
      form: {
        parentRegionNo: null, //上级编号
        parentRegionName: null, //上级名称
        regionNo: null, //区划编号
        regionName: null, //区划名称
        sortNo: null, //区划排序
        regionType: null, //区划等级
        remark: null //区划备注
      },
      //效验规则
      rules: {
        regionNoSuffix: [
          { required: true, message: '请输入区划编号', trigger: 'blur' }
        ],
        regionName: [
          { required: true, message: '请输入区划名称', trigger: 'blur' }
        ],
        regionType: [
          { required: true, message: '请选择区划等级', trigger: 'blur' }
        ],
        sortNo: [{ required: true, message: '请输入区划排序', trigger: 'blur' }]
      },
      addForm: {}, //新增下级表单
      treeFormData: {} //单击树节点后的数据
    }
  },
  created () {
    // this.getRegionTree()
  },
  async mounted () {
    //tree数据
    await this.getRegionTree()
  },
  methods: {
    //获取父级节点
    getParentNode () {
      // 找到默认选中节点的路径
      const path = this.findNodePath(this.data, this.clickNode?.id)
      console.log('clickNode', this.clickNode)
      console.log('path', path)
      // 如果路径不为空，则展开默认选中节点的父节点
      if (path) {
        this.expandedKeys = path
          .slice(0, -1)
          .map(node => node.id)
          .filter(ite => typeof ite !== 'undefined')
        console.log('expandedKeys', this.expandedKeys)
      }
    },
    //父级节点递归
    findNodePath (nodes, targetId, path = []) {
      for (const node of nodes) {
        path.push(node)
        if (node.id === targetId) {
          return path
        }
        if (node.children) {
          const found = this.findNodePath(node.children, targetId, path)
          if (found) {
            return found
          }
        }
        path.pop()
      }
      return null
    },
    // 表单重置
    reset () {
      this.form = {
        parentRegionNo: null, //上级编号
        parentRegionName: null, //上级名称
        regionNo: null, //区划编号
        regionName: null, //区划名称
        sortNo: null, //区划排序
        regionType: null, //区划等级
        remark: null //区划备注
      }
      this.resetForm('form')
    },
    // 行政树数据
    async getRegionTree () {
      this.isFixed = false
      this.loading = true
      const res = await getRegionTree()
      this.data = res.data
      this.$nextTick(() => {
        if (this.clickNode.id) {
          console.log('data', this.data)
          console.log('id', this.clickNode.id)
          this.getParentNode()
          this.$refs.tree.setCurrentKey(this.clickNode.id) //通过设置的ref设置setCurrentKey去渲染tree的默认选中
          this.handleNodeClick(this.clickNode)
        }
      })

      this.loading = false
      this.isFixed = true
    },
    //点击树节点
    async handleNodeClick (data) {
      this.loading = true
      this.type = null
      this.multiple = false //解锁删除按钮
      this.clickNode = data //点击的节点对象
      console.log('clickNode', data)
      this.defaultSelectedNodeId = this.clickNode.id
      const res = await getRegionDetail(data.id) //获取节点详情
      this.form = res.data.data //渲染页面
      console.log(this.form)
      this.form.regionNoPrefix = this.form.parentRegionNo
      this.form.regionNoSuffix = this.form.regionNo.replace(
        this.form.parentRegionNo,
        ''
      )
      this.treeFormData = this.form
      this.loading = false
    },
    //点击删除按钮
    delRegion () {
      this.$modal
        .confirm(
          '是否确认删除区域名称为"' + this.form.regionName + '"的数据项？'
        )
        .then(() => {
          return delRegion(this.clickNode.id)
        })
        .then(() => {
          this.reset()
          this.getRegionTree()
          this.$modal.msgSuccess('删除区域成功')
        })
        .catch(() => {})
    },
    //提交操作
    async onSubmit () {
      if (this.form.regionType >= 5) {
        this.$message({
          message: '区划等级不正确，最小等级为"乡镇、村委"',
          type: 'warning'
        })
        return
      }
      if (this.type === 'add') {
        this.form.parentNo = this.form.regionNoPrefix
        this.form.regionNo = this.form.regionNoPrefix + this.form.regionNoSuffix
        await addRegion(this.form)
        this.$message({
          message: '新增下级行政地区成功',
          type: 'success'
        })
      } else {
        this.form.parentNo = this.form.regionNoPrefix
        this.form.regionNo = this.form.regionNoPrefix + this.form.regionNoSuffix
        await updateRegion(this.form)
        this.$message({
          message: '修改行政地区成功',
          type: 'success'
        })
      }

      this.reset() //重置表单
      this.getRegionTree() //重新渲染
    },
    //刷新按钮
    refresh (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.reset() //重置表单
      this.getRegionTree() //重新渲染
    },
    //点击新增下级按钮
    async addRegion () {
      this.type = 'add'
      this.addForm = {
        parentRegionNo: this.treeFormData.regionNo, //上级编号
        parentNo: this.treeFormData.regionNo, //上级编号
        parentName: this.treeFormData.regionName, //上级名称
        parentRegionName: this.treeFormData.regionName, //上级名称
        parentId: this.treeFormData.regionId, //父级ID
        regionNo: null, //区划编号
        regionName: null, //区划名称
        sortNo: 1, //区划排序
        regionType: this.treeFormData.regionType + 1, //区划等级
        remark: null, //区划备注
        regionNoPrefix: this.treeFormData.regionNo, //
        regionNoSuffix: null
      }
      this.form = this.addForm
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input {
  width: 700px;
}
.desc {
  width: 700px;
}
::v-deep .el-textarea__inner {
  height: 150px;
}
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .district {
    height: calc(100vh - 98px);
    // width: 354px;
    width: 400px;
    margin: 0;
    margin-right: 10px;
  }
  .right {
    height: calc(100vh - 98px);
    width: calc(100% - 410px); //商品搜索条件
    margin: 0;
  }
}
// .fixedForm {
//   position: fixed;
//   /* 固定定位的盒子也必须写边偏移 */
//   top: 100px;
//   left: 250px;
// }
</style>
