<template>
  <!-- 操作(新增/编辑)oss对象储存弹窗 -->
  <div class="handleOssDialog">
    <el-dialog
      :title="title"
      :visible.sync="openDialog"
      v-dialogDrag="true"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content" v-loading="loading">
        <el-form label-width="90px" :model="form" :rules="rules" ref="form">
            <el-form-item label="是否启用">
              <el-switch v-model="form.isStop"> </el-switch>
            </el-form-item>
            <el-form-item label="oss类型" prop="ossId">
                    <el-select
                      v-model="form.ossId"
                      placeholder="请选择oss类型"
                      clearable
                      @blur="$refs.form.validateField('ossId')"
                    >
                      <el-option
                        v-for="dict in dict.type.oss_config_id"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>
                  </el-form-item>
          <el-form-item label="资源编号" prop="ossNo">
            <el-input
              v-model="form.ossNo"
              placeholder="请输入资源编号"
            />
          </el-form-item>
          <el-form-item label="资源地址" prop="ossAddress">
            <el-input
              v-model="form.ossAddress"
              placeholder="请输入资源地址"
            />
          </el-form-item>
          <el-form-item label="空间名" prop="ossSpaceName">
            <el-input v-model="form.ossSpaceName" placeholder="请输入空间名" />
          </el-form-item>
          <el-form-item label="accessKey" prop="accessKey">
            <el-input
              v-model="form.accessKey"
              placeholder="请输入accessKey"
            />
          </el-form-item>
          <el-form-item label="secretKey" prop="secretKey">
            <el-input
              v-model="form.secretKey"
              placeholder="请输入secretKey"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateOSS,//新增/修改
  OSSDetail,//详情
} from '@/api/tenant/resource/oss.js' //接口api
export default {
  name: 'HandleOssDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    //ossId
    curOssId:{}
  },
  dicts:['oss_config_id'],
  data () {
    return {
      loading: false, //遮罩层
      form: {
        operationType:'save',//操作类型（新增：save，修改：update)
        isStop:false,//是否启用
        ossId:undefined,//资源id
        ossNo:undefined,//资源编号
        ossAddress:undefined,//资源地址
        ossSpaceName:undefined,//空间名
        accessKey:undefined,//accessKey
        secretKey:undefined,//secretKey
      },
      title: '新增oss对象储存',
      //校验规则
      rules:{
        ossId: [
          { required: true, message: '请选择oss类型', trigger: ['blur','change'] }
        ],
        ossNo: [
          { required: true, message: '请输入资源编号', trigger: 'blur' }
        ],
        ossAddress: [
          { required: true, message: '请输入资源地址', trigger: 'blur' }
        ],
        ossSpaceName: [
          { required: true, message: '请输入空间名', trigger: 'blur' }
        ],
        accessKey: [
          { required: true, message: '请输入accessKey', trigger: 'blur' }
        ],
        secretKey: [
          { required: true, message: '请输入secretKey', trigger: 'blur' }
        ],
      }
    }
  },
  watch:{
    openDialog:{
     async handler (val) {
        if(val){
          if(this.curOssId !== undefined){
            this.loading = true
            this.title = '编辑oss对象储存'
             const res = await OSSDetail(this.curOssId)
             this.form = res.data
             this.form.operationType = 'update'
             this.loading = false
          }else{
            this.title = '新增oss对象储存'
            this.form.operationType = 'save'
          }
        }
      },
      immediate:true
    }
  },
  methods: {
    //关闭弹窗并初始化数据
    handleClose () {
      this.form = {
        operationType:'save',//操作类型（新增：save，修改：update)
        isStop:false,//是否启用
        ossId:undefined,//资源id
        ossNo:undefined,//资源编号
        ossAddress:undefined,//资源地址
        ossSpaceName:undefined,//空间名
        accessKey:undefined,//accessKey
        secretKey:undefined,//secretKey
      },
      this.resetForm('form')
      this.$emit('update:openDialog', false)
    },
    //提交表单
    submitForm () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try{
            await updateOSS(this.form)
            if(this.title === '新增oss对象储存') this.$message.success('新增成功')
            else this.$message.success('编辑成功')
            this.$emit('getList')
            this.handleClose()
          }catch{}
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.handleOssDialog {
  ::v-deep .el-dialog {
    max-width: 600px !important;
    min-width: 600px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 0px !important;
  }
  .btn {
    text-align: right;
    padding: 10px 0;
  }
}
</style>
