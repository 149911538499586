var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "wrap" } },
    [
      _c("cardTitleCom", {
        attrs: { cardTitle: "查询", id: "search-card" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isSearchInput: true,
                        isAdvancedSearch: false,
                        isBillDate: false,
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "安装包列表" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        multiple: _vm.multiple,
                        isDeleteBtn: true,
                      },
                      on: {
                        handleAdd: _vm.openDia,
                        handleDelete: _vm.deleteBtn,
                      },
                    }),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          "tooltip-effect": "dark",
                          border: "",
                          height: _vm.tableHeight,
                          "max-height": _vm.tableHeight,
                        },
                        on: {
                          "selection-change": _vm.handleSelectionChange,
                          "row-click": _vm.handleRowClick,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "55",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "index",
                            width: "80",
                            label: "序号",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productNo",
                            align: "center",
                            type: "index",
                            width: "180",
                            label: "产品编号",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDetail(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.productNo))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "setupProductName",
                            label: "产品名称",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "产品名称",
                            label: "安装包类型",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dict.type.sys_operating_system,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      scope.row.setupPackageType == item.value
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }
                                )
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packageFileResp.attachFileUrl",
                            label: "安装包地址",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packageFileResp.attachFileName",
                            label: "安装包名称",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packageFileResp.fileSize",
                            label: "安装包大小",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.packageFileResp != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.packageFileResp.fileSize
                                          ) + "KB"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "createTime",
                            label: "创建时间",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TablePagination", {
                      attrs: {
                        page: _vm.queryParams.pageNum,
                        limit: _vm.queryParams.pageSize,
                        total: _vm.total,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageSize", $event)
                        },
                        pagination: _vm.getList,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 500, showDialog: _vm.dialogVisible },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDia,
                        expression: "loadingDia",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品名称", prop: "productName" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "disabled-input",
                            staticStyle: { width: "220px" },
                            attrs: { size: "mini", placeholder: "请输入内容" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showProduct($event)
                              },
                            },
                            model: {
                              value: _vm.form.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productName", $$v)
                              },
                              expression: "form.productName",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-more more",
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "安装包类型",
                          prop: "setupPackageType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: { size: "mini", placeholder: "请选择" },
                            model: {
                              value: _vm.form.setupPackageType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "setupPackageType", $$v)
                              },
                              expression: "form.setupPackageType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.sys_operating_system,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上传安装包", required: "" } },
                      [
                        _c("OssUpload", {
                          attrs: { type: _vm.uploadType, useFileName: "" },
                          on: { change: _vm.successFile },
                          model: {
                            value: _vm.url,
                            callback: function ($$v) {
                              _vm.url = $$v
                            },
                            expression: "url",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.close2 } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.dialogSubmit },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selectProductDialog", {
        attrs: {
          openDialog: _vm.openSelectProductDialog,
          isRadio: true,
          productTypes: _vm.productTypes,
          isSunvunApp: _vm.isSunvunApp,
          status: 0,
        },
        on: {
          "update:openDialog": function ($event) {
            _vm.openSelectProductDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openSelectProductDialog = $event
          },
          select: _vm.selectSms,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }