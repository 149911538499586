<template>
  <!-- oss对象储存 -->
  <div class="oss" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            :isAdvancedSearch="false"
            :isBillDate="false"
            isSearchInputTitle="全部/资源编号/空间名"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="oss对象储存列表">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="multiple"
            :isDeleteBtn="true"
            :isCopyBtn="true"
            :isEnableBtn="true"
            @handleAdd="clickBtn('新增')"
            @handleDelete="clickBtn('删除')"
          />
          <!-- 表格区域 -->
          <div class="table">
            <!-- 表格 -->
            <el-table
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="50" align="center">
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="ossNo"
                label="资源编号"
                align="center"
                width="260"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click.native.prevent="clickBtn('编辑', scope.row.ossId)"
                    >{{ scope.row.ossNo }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="oss类型" align="center" prop="ossId">
                <template slot-scope="scope">
                  <dict-tag
                    :options="dict.type.oss_config_id"
                    :value="scope.row.ossId"
                  />
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-if="item.prop === 'isStop'"
                    v-model="scope.row.isStop"
                    @change="clickBtn('启用', scope.row.ossId)"
                  ></el-switch>
                  <div v-else>{{ scope.row[item.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
            <TablePagination
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              :total="total"
              @pagination="getList"
            ></TablePagination>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 操作(新增/编辑)oss对象储存弹窗 -->
    <handleOssDialog
      :openDialog.sync="openDialog"
      :curOssId="curOssId"
      @getList="getList"
    />
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import handleOssDialog from '@/views/tenant/resource/oss/components/handleOssDialog' //操作(新增/编辑)oss对象储存弹窗
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import OperatingButton from '@/views/components/operatingButton/index.vue' //按钮区域组件
import {
  OSSList, //列表
  delOSS, //删除
  isStopOSS //启用禁用
} from '@/api/tenant/resource/oss.js' //接口api
export default {
  name: 'OSS',
  components: {
    cardTitleCom,
    OperatingButton,
    handleOssDialog,
    seniorSearch,
    TablePagination
  },
  dicts: ['oss_config_id'],
  data () {
    return {
      loading: false, //遮罩层
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        ossNo: undefined, //资源编号
        ossSpaceName: undefined //accessKey
      },
      tableData: [], //表格数据
      total: 0,
      curOssId: undefined, //当前ossId
      ids: [], //表格选中的ids
      names: [], //表格选中的名字
      openDialog: false, //操作(新增/编辑)oss对象储存弹窗开关
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'ossAddress', title: '资源地址' },
        { prop: 'ossSpaceName', title: '空间名' },
        { prop: 'accessKey', title: 'accessKey' },
        { prop: 'secretKey', title: 'secretKey' },
        { prop: 'isStop', title: '是否启用', width: '160' }
      ],
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'ossNos', name: '资源编号' },
        { parameter: 'ossSpaceNames', name: '空间名' }
      ],
      // 非多个禁用
      multiple: true,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70 // 分页组件高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 120
    }
  },
  created () {
    this.getList()
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    async clickBtn (type, ossId) {
      if (type === '新增') {
        this.curOssId = undefined
        this.openDialog = true
      } else if (type === '编辑') {
        console.log(ossId)
        this.curOssId = ossId
        this.openDialog = true
      } else if (type === '启用') {
        try {
          await isStopOSS([ossId])
          this.$message.success('操作成功')
        } catch {}
      } else if (type === '删除') {
        if (this.ids.length <= 0) return this.$message.error('请选择oss')
        this.$confirm(`确定删除${this.names.join(',')}oss`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delOSS(this.ids)
            this.$message.success('删除成功')
            this.getList()
          })
          .catch(() => {})
      } else {
        this.handleQuery()
      }
    },
    //表格选中的数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.ossId)
      this.names = selection.map(item => item.ossAddress)
    },
    //搜索
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //重置搜索
    resetQuery () {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        ossNo: undefined, //资源编号
        ossSpaceName: undefined //accessKey
      }
      this.getList()
    },
    /** 查询列表 */
    getList () {
      this.loading = true
      OSSList(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.oss {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;
    }

    .el-input,
    .el-select {
      width: 200px;
    }
  }

  .tableContent {
    padding: 10px 10px 0 10px;
  }
}
</style>
