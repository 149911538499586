<template>
  <!-- 商户排行 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { tenantRank, tenantRankTotal } from '@/api/tenant/saleData'
import TablePage from '@/components/tablePage/index.vue'
export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data () {
    return {
      options: {
        // 新增自定义合计计算
        customTotal: {
          apiHost: tenantRankTotal,
          list: [
            {
              title: '总交易笔数',
              key: 'totalTransactions',
              value: '0.00'
            },
            {
              title: '总交易金额',
              key: 'totalMoney',
              value: '0.00'
            }
          ]
        },
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          query: undefined //商户名称、商户号、门店名称、门店编码、支付方式
        },
        getListApi: tenantRank,
        title: '商户排行',
        rowKey: 'tenantNo',
        type: 'tenantNo',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '160' },
          { prop: 'tenantName', label: '商户名称', minWidth: '160' },
          { prop: 'statisticalTime', label: '统计时间', minWidth: '160' },
          { prop: 'transactionCount', label: '交易笔数', minWidth: '160' },
          { prop: 'transactionMoney', label: '交易金额', minWidth: '160' }
        ],
        list: []
      }
    }
  }
}
</script>
